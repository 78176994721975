import React from "react";
import softerrific from "../images/icons/softerrific.svg";
import dev from "../images/footer/devtoicon.svg";
import twitter from "../images/footer/twittericon.svg";
import archive from "../images/footer/archiveicon.svg";
import linkedin from "../images/footer/linkedinicon.svg";
import mousepaw from "../images/footer/mousepawicon.svg";
import spotify from "../images/footer/spotifyicon.svg";
import apple from "../images/footer/appleicon.svg";
import google from "../images/footer/googleicon.svg";
import mastodon from "../images/footer/mastodonicon.svg";

const Footer = () => {
  // const [hidden, setHidden] = useState(true);

  // const toggleHidden = () => {
  //   setHidden(!hidden);
  // };

  return (
    <footer>
      {/* <div className={`footer-content ${hidden ? 'footer-hidden' : null}`}> */}
      <div className={`footer-content`}>
        <div className="footer-links">
          <a rel="me" href="https://hachyderm.io/@bughunterscafe" target="_blank">
            <img src={mastodon} alt="mastodon" />
          </a>
          <a href="https://twitter.com/BugHuntersCafe" target="_blank" rel="noreferrer">
            <img src={twitter} alt="twitter" />
          </a>
          <a
            href="https://archive.org/details/@bughunterscafe?tab=uploads"
            target="_blank"
            rel="noreferrer"
          >
            <img src={archive} alt="archive" />
          </a>
          <a
            href="https://www.linkedin.com/company/bug-hunters-cafe"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="linkedin" />
          </a>
          <a href="https://dev.to/bughunterscafe" target="_blank" rel="noreferrer">
            <img src={dev} alt="dev" />
          </a>
        </div>
        <div className="listen-on-group">
          LISTEN ON{" "}
          <div className="footer-links">
            <a
              href="https://open.spotify.com/show/3YzwGQfSz9Cv428sT4Vsqs"
              target="_blank"
              rel="noreferrer"
            >
              <img src={spotify} alt="spotify" />
            </a>
            <a
              href="https://podcasts.apple.com/us/podcast/the-bug-hunters-caf%C3%A9/id1556496590"
              target="_blank"
              rel="noreferrer"
            >
              <img src={apple} alt="apple" />
            </a>
            <a
              href="https://podcasts.google.com/feed/aHR0cHM6Ly9idWdodW50ZXJzLmNhZmUvcnNzL2J1Z2h1bnRlcnNjYWZlLnhtbA"
              target="_blank"
              rel="noreferrer"
            >
              <img src={google} alt="google" />
            </a>
          </div>
          <a href="/listen">SEE ALL</a>
        </div>
        <div className="sponsors">
          <div className="brand">
            <div className="footer-powered">
              SPONSORED BY{" "}
              <span className="text-pink bold">SOFTERRIFIC</span>
            </div>
            <div className="footer-powered">
              {" "}
              AND <span className="text-yellow bold">MOUSEPAW MEDIA</span>
            </div>
          </div>
          <div className={`softerrific`}>
            <a href="https://softerrific.com" target="_blank" rel="noreferrer">
              <img src={softerrific} alt="softerrific" />
            </a>
          </div>
          <div className={`mousepaw`}>
            <a href="https://mousepawmedia.com" target="_blank" rel="noreferrer">
              <img src={mousepaw} alt="mousepaw media" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
