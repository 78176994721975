import React, { useEffect } from "react";

const AboutUs = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);
  return (
    <section className="about-us wrapper">
      <div className="title-wrapper">
        <h1>{props.title}</h1>
      </div>
      <div className="about-us-content">
        <p>
          The Bug Hunters Café is a podcast all about making and fixing mistakes
          in code. Hosted by developers Jason C. McDonald and Bojan Miletić,
          both engaging tech speakers, and featuring a diverse line-up of guests
          from all corners of the tech world, The Bug Hunters Café takes a
          humorous and curious perspective on bugs, project planning faux pas,
          and everything else that can go wrong in software development. Grab a
          mug of your favorite beverage, pull up a chair, and join us for new
          conversations every other week.
        </p>
        <div className="credits">
          <div>
            <h3>Hosted by</h3>
            <ul>
              <li>Jason C. McDonald</li>
              <li>Bojan Miletić</li>
              <li>Jessica Jang</li>
              <li>Anne McDonald</li>
              <li>{"Jovana Mijalković (Season 1)"}</li>
              <li>{"Marta Radić (Season 1)"}</li>
            </ul>
          </div>
          <div>
            <h3>Producer</h3>
            <ul>
              <li>Jason C. McDonald</li>
            </ul>
          </div>
          <div>
            <h3>Executive Producer</h3>
            <ul>
              <li>Bojan Miletić</li>
            </ul>
          </div>
          <div>
            <h3>Engineer</h3>
            <ul>
              <li>Talal Bouroki</li>
              <li>{"Jovana Mijalković (Season 1)"}</li>
            </ul>
          </div>
          <div>
            <h3>Writer</h3>
            <ul>
              <li>Jason C. McDonald</li>
            </ul>
          </div>
          <div>
            <h3>Graphics Designer</h3>
            <ul>
              <li>Jovana Mijalković</li>
            </ul>
          </div>
          <div>
            <h3>Website</h3>
            <ul>
              <li>Jonathan Cole Rabe</li>
              <li>{"Petar Petrov (Season 1)"}</li>
            </ul>
          </div>
          <div>
            <h3>Sponsored by</h3>
            <ul>
              <li>
                <a href="https://softerrific.com" target="_blank" rel="noreferrer">
                  Softerrific
                </a>
              </li>
              <li>
                <a href="https://mousepawmedia.com" target="_blank" rel="noreferrer">
                  Mousepaw Media
                </a>
              </li>
              <li>
                <a href="https://manning.com" target="_blank" rel="noreferrer">
                  Manning Publications
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3>Music provided by</h3>
            <ul>
              <li>
                <a href="https://audionautix.com" target="_blank" rel="noreferrer">
                  Audionautix.com
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3>Podcast hosting by</h3>
            <ul>
              <a href="https://www.archive.org" target="_blank" rel="noreferrer">
                Internet Archive
              </a>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
