import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import playIcon from "../images/icons/play.svg";
import { Link } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import episodes from "./episodeList";

const EpisodesSlider = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(
    episodes.length - 1
  );

  const [initialLoad, setInitialLoad] = useState(true);

  const slider = useRef(null);

  useEffect(() => {
    initialLoad && slider?.current?.slickGoTo(episodes.length - 1);
    setInitialLoad(false);
  }, [currentSlideIndex, initialLoad]);

  const beforeChange = (prev, current, next) => {
    cancelPlay();
  };

  const afterChange = (currentSlide) => {
    setCurrentSlideIndex(currentSlide);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: episodes.length <= 3 ? episodes.length - 1 : 3,
    slidesToScroll: 1,
    centerMode: episodes.length > 3,
    initialSlide: episodes.length - 1,
    row: 0,
    arrows: true,
    beforeChange,
    afterChange,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [stateEpisodes, setStateEpisodes] = useState(episodes);

  const cancelPlay = () => {
    let newArr = [...stateEpisodes];
    newArr.forEach((element) => {
      element.playing = false;
    });
    setStateEpisodes(newArr);
  };

  const playEpisode = (index) => {
    if (index === currentSlideIndex) {
      let newArr = [...stateEpisodes];
      newArr.forEach((element) => {
        element.playing = false;
      });
      newArr[index].playing = true;
      setStateEpisodes(newArr);
    }
  };

  return (
    <div className="episodes-slider">
      <Slider ref={slider} {...sliderSettings} id="slider">
        {stateEpisodes.map((episode, index) => {
          return (
            <div key={episode.id} className="episode-slide">
              <Link
                to={"/episode/" + episode.id}
                className="episode-wrapper"
                style={{ backgroundImage: `url("${episode.image}")` }}
              >
                <div className="episode-play-area">
                  {/* {episode.playing ? (
                    <div className="episode-player-wrapper">
                      <AudioPlayer
                        showJumpControls={false}
                        src={episode.link}
                        autoPlay={false}
                      />
                    </div>
                  ) : (
                    <img
                      src={playIcon}
                      alt="play"
                      onClick={() => playEpisode(index)}
                      className="episode-play-btn"
                    />
                  )} */}
                  {episode.playing ? (
                    <div className="episode-player-wrapper">
                      <AudioPlayer
                        showJumpControls={false}
                        src={episode.link}
                        autoPlay={false}
                      />
                    </div>
                  ) : (
                    <img
                      src={playIcon}
                      alt="play"
                      onClick={() => playEpisode(index)}
                      className="episode-play-btn"
                    />
                  )}
                </div>
                <div className="episode-info">
                  <div>
                    <p>{episode.info}</p>
                    <h2>{episode.title}</h2>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default EpisodesSlider;
