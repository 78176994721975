import tunein from '../images/social/tunein.svg';
import rss from '../images/social/rss.svg';
import iheart from '../images/social/iheart.svg';
import spotify from '../images/social/spotify.svg';
import internetarchive from '../images/social/internetarchive.svg';
import google from '../images/social/google.svg';
import overcast from '../images/social/overcast.svg';
import dev from '../images/social/dev.svg';
import apple from '../images/social/apple.svg';
import acast from '../images/social/acast.svg';
import podcastAddict from '../images/social/podcastAddict.svg';
import radioPublic from '../images/social/radioPublic.svg';
import pocketCasts from '../images/social/pocketCasts.svg';
import stitcher from '../images/social/stitcher.svg';
import podChaser from '../images/social/podChaser.svg';
import listenNotes from '../images/social/listenNotes.svg';
import bullhorn from '../images/social/bullhorn.svg';
import playerFM from '../images/social/playerFM.svg';

export const socialArray = [
  {
    id: 1,
    link:
      'https://tunein.com/podcasts/Technology-Podcasts/The-Bug-Hunters-Cafe-p1413511',
    image: tunein,
  },
  {
    id: 2,
    link: 'https://bughunters.cafe/rss/bughunterscafe.xml',
    image: rss,
  },
  {
    id: 3,
    link: 'https://www.iheart.com/podcast/78414396/',
    image: iheart,
  },
  {
    id: 4,
    link: 'https://open.spotify.com/show/3YzwGQfSz9Cv428sT4Vsqs',
    image: spotify,
  },
  {
    id: 5,
    link: 'https://archive.org/details/@bughunterscafe',
    image: internetarchive,
  },
  {
    id: 6,
    link:
      'https://podcasts.google.com/feed/aHR0cHM6Ly9idWdodW50ZXJzLmNhZmUvcnNzL2J1Z2h1bnRlcnNjYWZlLnhtbA',
    image: google,
  },
  {
    id: 7,
    link: 'https://overcast.fm/itunes1556496590',
    image: overcast,
  },
  {
    id: 8,
    link: 'https://dev.to/bughunters',
    image: dev,
  },
  {
    id: 9,
    link:
      'https://podcasts.apple.com/us/podcast/the-bug-hunters-caf%C3%A9/id1556496590',
    image: apple,
  },
  {
    id: 10,
    link: 'https://play.acast.com/s/the-bug-hunters-cafe',
    image: acast,
  },
  {
    id: 11,
    link: 'https://podcastaddict.com/podcast/3265884',
    image: podcastAddict,
  },
  {
    id: 12,
    link: 'https://radiopublic.com/the-bug-hunters-caf-G3E4XZ',
    image: radioPublic,
  },
  {
    id: 13,
    link: 'https://pca.st/yg9jvd7b',
    image: pocketCasts,
  },
  {
    id: 14,
    link: 'https://www.stitcher.com/s?fid=615395',
    image: stitcher,
  },
  {
    id: 15,
    link: 'https://www.podchaser.com/podcasts/-1609539',
    image: podChaser,
  },
  {
    id: 16,
    link:
      'https://www.listennotes.com/podcasts/the-bug-hunters-caf%C3%A9-the-bug-hunters-caf%C3%A9-BT8pSsoiIAQ/',
    image: listenNotes,
  },
  {
    id: 17,
    link: 'https://www.bullhorn.fm/thebughunterscafe',
    image: bullhorn,
  },
  {
    id: 18,
    link: 'https://player.fm/series/the-bug-hunters-cafe',
    image: playerFM,
  },
];
