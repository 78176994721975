import episode1 from "../images/bg_episode_1.jpg";
import episode2 from "../images/bg_episode_2.jpg";
import episode3 from "../images/bg_episode_3.jpg";
import episode4 from "../images/bg_episode_4.jpg";
import episode5 from "../images/bg_episode_5.jpg";
import episode6 from "../images/bg_episode_6.jpg";
import episode7 from "../images/bg_episode_7.jpg";
import episode8 from "../images/bg_episode_8.jpg";
import episode9 from "../images/bg_episode_9.png";
import episode10 from "../images/bg_episode_10.png";
import episode11 from "../images/bg_episode_11.png";
import episode12 from "../images/bg_episode_12.png";

const episodes = [
  {
    id: 1,
    image: episode1,
    title: "Episode 1: The Closet, The Nerds, and the Unicorn",
    summary:
      "In the series premiere of The Bug Hunters Café, software developers Bojan Miletic and Jason C. McDonald chat about their bug hunting experiences across various languages, and some of their biggest coding mistakes.",
    link: "http://archive.org/download/BugHuntersCafeEp1/BugHuntersCafeEp1.mp3",
    info: "51min",
    note: `<div><p>Music by: </p><a href="https://audionautix.com" target="_blank" rel="noreferrer">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank" rel="noreferrer">https://www.softerrific.com</a></div>
    `,
  },
  {
    id: 2,
    image: episode2,
    title: "Episode 2: A Glitch In Time (M. Scott Ford)",
    summary:
      "Things are a bit timey-wimey at the café as M. Scott Ford chats with Bojan and Jason about legacy code, test refactoring, and what it means to be a 'mender'.",
    link: "https://archive.org/download/BugHuntersCafeEp2/BugHuntersCafeEp2.mp3",
    info: "67min",
    note: `<div><p>Corgibytes: </p><a href="https://corgibytes.com">https://corgibytes.com</a></div>
           <div><p>Legacy Code Rocks!: </p><a href="https://www.legacycode.rocks">https://www.legacycode.rocks</a></div>
           <div><p>M. Scott Ford (Twitter): </p><a href="https://twitter.com/mscottford">https://twitter.com/mscottford</a></div>
           <div><p>M. Scott Ford (LinkedIn): </p><a href="https://linkedin.com/in/mscottford">https://linkedin.com/in/mscottford</a></div>
           <div><p>Topics: podcast, programming, debugging, coding</p></div>
           <div><p>Language: English</p></div>
           <div><p>Music by: </p><a href="https://audionautix.com" target="_blank" rel="noreferrer">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank" rel="noreferrer">https://www.softerrific.com</a></div>`,
  },
  {
    id: 3,
    image: episode3,
    title: "Episode 3: To Boldly Debug (Moshe Zadka)",
    summary:
      "Guest Moshe Zadka joins Jason and Bojan to unpack the mystery surrounding what DevOps and site reliability engineering actually are.",
    link: "https://archive.org/download/BugHuntersCafeEp3/BugHuntersCafeEp3.mp3",
    info: "55min",
    note: `<div><p>Moshe Zadka (Website): </p><a href="https://cobordism.com" target="_blank" rel="noreferrer">https://cobordism.com</a></div>
           <div><p>Moshe Zadka, Glyph Creating And Consuming Modern Web Services with Twisted PyCon 2017: </p><a href="https://www.youtube.com/watch?v=ztkBG4qLR_4" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=ztkBG4qLR_4</a></div>
           <div><p>Topics: podcast, programming, debugging, coding</p></div>
           <div><p>Language: English</p></div>
           <div><p>Music by: </p><a href="https://audionautix.com" target="_blank" rel="noreferrer">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank" rel="noreferrer">https://www.softerrific.com</a></div>`,
  },
  {
    id: 4,
    image: episode4,
    title: "Episode 4: Attack of the Fresh Fruit (Cheuk Ting Ho)",
    summary:
      "Amidst a unicorn-caused fall of fruit, guest Cheuk Ting Ho discusses what developer advocates (or are they avocados?) really are, and what role they play in preventing bugs.",
    link: "https://archive.org/download/BugHuntersCafeEp4/BugHuntersCafeEp4.mp3",
    info: "53min",
    note: `<div><p>Follow Cheuk on Twitter: </p><a href="https://twitter.com/cheukting_ho" target="_blank" rel="noreferrer">https://twitter.com/cheukting_ho</a></div>
           <div><p>Cheuk on Twitch: </p><a href="https://www.twitch.tv/cheukting_ho" target="_blank" rel="noreferrer">https://www.twitch.tv/cheukting_ho</a></div>
           <div><p>Topics: podcast, programming, debugging, coding</p></div>
           <div><p>Language: English</p></div>
           <div><p>Music by: </p><a href="https://audionautix.com" target="_blank" rel="noreferrer">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank" rel="noreferrer">https://www.softerrific.com</a></div>`,
  },
  {
    id: 5,
    image: episode5,
    title: "Episode 5: Bojan Reads the Documentation (Anastasiia Tymoshchuk)",
    summary:
      "Anastasiia Tymoshchuk joins Bojan and Jason to discuss all things team management: blame vs. accountability, debugging the cause of a problem, zombies, timers and...planking? Maybe she can figure out why the espresso machine is dispensing garlic sprinkles.",
    link: "https://archive.org/download/BugHuntersCafeEp5/BugHuntersCafeEp5.mp3",
    info: "55min",
    note: `<div><p>Topics: podcast, programming, debugging, coding</p></div>
           <div><p>Language: English</p></div>
           <div><p>Music by: </p><a href="https://audionautix.com" target="_blank" rel="noreferrer">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank" rel="noreferrer">https://www.softerrific.com</a></div>
           <div><p>Publication date: 2021-05-02</p></div>`,
  },
  {
    id: 6,
    image: episode6,
    title: "Episode 6: Mentorship with Cheese Sauce (Vaidehi Joshi)",
    summary:
      "Vaidehi Joshi stops into the café to help fix the temporal stabilizer, and stays to chat with Jason and Bojan about teaching, teams, and where bugs come from in the first place.",
    link: "https://archive.org/download/BugHuntersCafeEp6/BugHuntersCafeEp6.mp3",
    info: "62min",
    note: `<div><p>Topics: podcast, programming, debugging, coding</p></div>
           <div><p>Language: English</p></div>
           <div><p>Music by: </p><a href="https://audionautix.com" target="_blank" rel="noreferrer">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank" rel="noreferrer">https://www.softerrific.com</a></div>
           <div><p>Publication date: 2021-05-31</p></div>`,
  },
  {
    id: 7,
    image: episode7,
    title: "Episode 7: Brain Scans and Networking (Joshua Jacob)",
    summary:
      "What do MRI scans, wood turning, and interpersonal networking have in common? They're all topics that Joshua Jacobs knows a thing or five about. Join him, Bojan, and Jason as they discuss everything from floating-point numbers in supervillain plots to the possible reasons why a statistician might have a beef with a programmer.",
    link: "https://archive.org/download/BugHuntersCafeEp7/BugHuntersCafeEp7.mp3",
    info: "68min",
    note: `<div><p>Topics: podcast, programming, debugging, coding</p></div>
           <div><p>Language: English</p></div>
           <div><p>Music by: </p><a href="https://audionautix.com" target="_blank" rel="noreferrer">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank" rel="noreferrer">https://www.softerrific.com</a></div>
           <div><p>Publication date: 2021-06-13</p></div>`,
  },
  {
    id: 8,
    image: episode8,
    title: "Episode 8: More Power To You (Amber Vanderburg & Laís Carvalho)",
    summary: (
      <p>
        'Amber Vanderburg and Laís Carvalho stop into the café to discuss
        debugging teams, promoting diversity, and creating safe spaces. Turns
        out, that can have a big impact on code, too! Meanwhile, Marta tries to
        keep the café's power supply stable.'
      </p>
    ),
    link: "https://archive.org/download/BugHuntersCafeEp8/BugHuntersCafeEp8.mp3",
    info: "61min",
    note: `<div><p>Music by: </p><a href="https://audionautix.com" target="_blank" rel="noreferrer">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank" rel="noreferrer">https://www.softerrific.com</a></div>`,
  },
  {
    id: 9,
    image: episode9,
    title: "Episode 9: Sneeze Decor (Naomi Ceder)",
    summary: (
      <p>
        'Amidst magically changing decor, Naomi Ceder chats with Bojan and Jason
        about old school hacking and how to give great conference talks. And the
        unicorn sneezes on...'
      </p>
    ),
    link: "https://ia801503.us.archive.org/30/items/BugHuntersCafeEp9/BugHuntersCafeEp9.mp3",
    info: "71min",
    note: `<div><p>Music by: </p><a href="https://audionautix.com" target="_blank" rel="noreferrer">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank" rel="noreferrer">https://www.softerrific.com</a></div>`,
  },
  {
    id: 10,
    image: episode10,
    title: "Episode 10: The Squirrel Blockade (April Wensel)",
    summary: (
      <p>
        'April Wensel talks compassion and empathy in coding, while Marta
        negotiates with an army of enraged squirrels...'
      </p>
    ),
    link: "https://ia601509.us.archive.org/14/items/BugHuntersCafeEp10/BugHuntersCafeEp10.mp3",
    info: "55min",
    note: `<div><p>Music by: </p><a href="https://audionautix.com" target="_blank" rel="noreferrer">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank" rel="noreferrer">https://www.softerrific.com</a></div>`,
  },
  {
    id: 11,
    image: episode11,
    title: "Episode 11: Wool and Language Standards (JeanHeyd Meneide)",
    summary: (
      <p>
        The café has collided with the Sheepiverse, so who would be more
        appropriate as a guest than C Standard Project Editor and part-time
        sheep JeanHeyd Meneide? Grab a hay smoothie and listen is as JeanHeyd,
        Bojan, and Jason discuss language standards, programming history, and
        spooky C++ bugs. Baaaaa.
      </p>
    ),
    link: "https://ia601509.us.archive.org/15/items/BugHuntersCafeEp11/BugHuntersCafeEp11.mp3",
    info: "72min",
    note: `<div><p>Music by: </p><a href="https://audionautix.com" target="_blank" rel="noreferrer">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank" rel="noreferrer">https://www.softerrific.com</a></div>`,
  },
  {
    id: 12,
    image: episode12,
    title: "Episode 12: The Café Within (Ned Batchelder)",
    summary: (
      <p>
        'Somewhere in the midst of an infinitely recursing café, Ned Batchelder
        joins Bojan and Jason to talk about the wiles of testing and code
        coverage, and how to answer questions online without scaring off the new
        kids. What does total code coverage mean for your project, and just how
        useful is TDD in the real world anyway? Grab a cup of your favorite
        beverage and find out...'
      </p>
    ),
    link: "https://ia601408.us.archive.org/27/items/BugHuntersCafeEp12/BugHuntersCafeEp12.mp3",
    info: "67min",
    note: `<div><p>Music by: </p><a href="https://audionautix.com" target="_blank" rel="noreferrer">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank" rel="noreferrer">https://www.softerrific.com</a></div>`,
  },
  {
    id: 13,
    image: episode1,
    title: "Season 2, Episode 1: Sedat Kapanoğlu",
    summary: (
      <p>
        'Sedat Kapanoğlu swings by the café to talk about "street coding," while
        the café's new barista, Jess, arbitrates a wager between Bojan and Alan
        Turing. Listen in to learn more about the realities of changing
        requirements, the secret to true user privacy, and how it can be
        possible that monoliths aren't evil! (No, really!)'
      </p>
    ),
    link: "https://ia601402.us.archive.org/11/items/BugHuntersCafeS2Ep1/BugHuntersCafeS2Ep1.mp3",
    info: "60min",
    note: `<div><p>Sedat Kapanoğlu: </p><a href="https://ssg.dev" target="_blank" rel="noreferrer">https://ssg.dev</a></div>
           <div><p>"Street Coder" by Sedat Kapanoğlu: </p><a href="https://www.manning.com/books/street-coder" target="_blank" rel="noreferrer">https://www.manning.com/books/street-coder</a></div>
           <div><p>Music by: </p><a href="https://audionautix.com" target="_blank" rel="noreferrer">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank" rel="noreferrer">https://www.softerrific.com</a></div>
           <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com" target="_blank" rel="noreferrer">https://mousepawmedia.com</a></div>
           <div><p>Sponsored by Manning Publications: </p><a href="https://manning.com" target="_blank" rel="noreferrer">https://manning.com</a></div>`,
  },
  {
    id: 14,
    image: episode2,
    title: "Season 2, Episode 2: Felienne Hermans",
    summary: (
      <p>
        'Programmers learn by doing, right? Felienne Hermans chats with Bojan
        and Jason about how READING code may actually be even more important. So
        why it's so hard to understand someone else's code? The answer may be
        found somewhere between Shakespeare, trombones, and your ABCs. Now if
        Annie and Jess can only get the patrons to stop hacking their new
        robot...'
      </p>
    ),
    link: "https://ia801500.us.archive.org/13/items/BugHuntersCafeS2Ep2/BugHuntersCafeS2Ep2.mp3",
    info: "64min",
    note: `<div><p>Felienne Hermans: </p><a href="https://www.felienne.com" target="_blank" rel="noreferrer">https://www.felienne.com</a></div>
           <div><p>Code Reading Club: </p><a href="https://codereading.club" target="_blank" rel="noreferrer">https://codereading.club</a></div>
           <div><p>"The Programmer's Brain" by Felienne Hermans:</p><a href="https://www.manning.com/books/the-programmers-brain" target="_blank" rel="noreferrer">https://www.manning.com/books/the-programmers-brain</a></div>
           <div><p>"Dreaming in Code" by Scott Rosenberg: </p><a href="https://www.penguinrandomhouse.com/books/157305/dreaming-in-code-by-scott-rosenberg/" target="_blank" rel="noreferrer">https://www.penguinrandomhouse.com/books/157305/dreaming-in-code-by-scott-rosenberg/</a></div>
           <div><p>"Trombone Player Wanted" by Marcus Buckingham: </p><a href="https://www.amazon.com/Trombone-Player-Wanted-Marcus-Buckingham/dp/B000WCDIL4" target="_blank" rel="noreferrer">https://www.amazon.com/Trombone-Player-Wanted-Marcus-Buckingham/dp/B000WCDIL4</a></div>
           <div><p>"Code Reading: The Open Source Perspective" by Diomidis Spinellis:</p><a href="https://www.pearson.com/us/higher-education/program/Spinellis-Code-Reading-The-Open-Source-Perspective/PGM110367.html" target="_blank" rel="noreferrer">https://www.pearson.com/us/higher-education/program/Spinellis-Code-Reading-The-Open-Source-Perspective/PGM110367.html</a></div>
           <div><p>Music by: </p><a href="https://audionautix.com" target="_blank" rel="noreferrer">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank" rel="noreferrer">https://www.softerrific.com</a></div>
           <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com" target="_blank" rel="noreferrer">https://mousepawmedia.com</a></div>
           <div><p>Sponsored by Manning Publications: </p><a href="https://manning.com" target="_blank" rel="noreferrer">https://manning.com</a></div>`,
  },
  {
    id: 15,
    image: episode3,
    title: "Season 2, Episode 3: Marco Faella",
    summary: (
      <p>
        Despite the crimes against pizza being committed by the unicorn, Marco
        Faella comes by the café to discuss seven principles for making code
        better. Is it possible that software can be truly good? Can code golfing
        make you a better developer? Will the ANSI committee ban pineapple on
        pizza forever? Listen for the answers, and way too many anchovies.
      </p>
    ),
    link: "https://ia801506.us.archive.org/5/items/BugHuntersCafeS2Ep3/BugHuntersCafeS2Ep3.mp3",
    info: "57min",
    note: `<div><p>Marco Faella: </p><a href="http://wpage.unina.it/m.faella" target="_blank" rel="noreferrer">http://wpage.unina.it/m.faella</a></div>
           <div><p>"Seriously Good Software" by Marco Faella:</p><a href="http://mng.bz/NxBN" target="_blank" rel="noreferrer">http://mng.bz/NxBN</a></div>
           <div><p>Music by: </p><a href="https://audionautix.com" target="_blank" rel="noreferrer">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank">https://www.softerrific.com</a></div>
           <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com" target="_blank">https://mousepawmedia.com</a></div>
           <div><p>Sponsored by Manning Publications: </p><a href="https://manning.com" target="_blank">https://manning.com</a></div>`,
  },
  {
    id: 16,
    image: episode4,
    title: "Season 2, Episode 4: Max Guernsey III",
    summary: (
      <p>
        Max Guernsey III stops in to discuss requirement writing, motion over
        progress, and how to prevent bugs, rather than fix them. How is that
        even possible? How is scrum involved? And what is the unicorn getting
        Bojan for his birthday? Listen in for these answers and more.
      </p>
    ),
    link: "https://ia801400.us.archive.org/29/items/BugHuntersCafeS2Ep4/BugHuntersCafeS2Ep4.mp3",
    info: "61min",
    note: `<div><p>"Unit Testing with Java, JUnit 5, and IntelliJ IDEA" Live Project by Max Guernsey III: </p><a href="https://www.manning.com/liveprojectseries/unit-testing-with-Java-JUnit-5-and-IntelliJ-IDEA" target="_blank">https://www.manning.com/liveprojectseries/unit-testing-with-Java-JUnit-5-and-IntelliJ-IDEA</a></div>
           <div><p>Music by: </p><a href="https://audionautix.com" target="_blank">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank">https://www.softerrific.com</a></div>
           <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com" target="_blank">https://mousepawmedia.com</a></div>
           <div><p>Sponsored by Manning Publications: </p><a href="https://manning.com" target="_blank">https://manning.com</a></div>`,
  },
  {
    id: 17,
    image: episode5,
    title: "Season 2, Episode 5: Lily Mara",
    summary: (
      <p>
        Lily Mara pops into a slightly-more-chaotic-than-usual café to talk
        about Rust, and it's a far cry from the usual "let's rewrite everything"
        you may be expecting. While she chats with Bojan and Jason about
        refactoring to Rust, Jess and Annie try to figure out how to make the
        most of the latest magical glitch.
      </p>
    ),
    link: "https://ia601401.us.archive.org/18/items/BugHuntersCafeS2Ep5/BugHuntersCafeS2Ep5.mp3",
    info: "65min",
    note: `<div><p>Lily Mara: </p><a href="https://lilymara.xyz" target="_blank">https://lilymara.xyz</a></div>
           <div><p>Refactoring to Rust" by Lily Mara: </p><a href="https://www.manning.com/books/refactoring-to-rust" target="_blank">https://www.manning.com/books/refactoring-to-rust</a></div>
           <div><p>Music by: </p><a href="https://audionautix.com" target="_blank">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank">https://www.softerrific.com</a></div>
           <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com" target="_blank">https://mousepawmedia.com</a></div>
           <div><p>Sponsored by Manning Publications: </p><a href="https://manning.com" target="_blank">https://manning.com</a></div>`,
  },
  {
    id: 18,
    image: episode6,
    title: "Season 2, Episode 6: Mikolaj Pawlikowski",
    summary: (
      <p>
        A little chaos is a wonderful thing. Miko Pawlikowski chats about chaos
        engineering, and how breaking things deliberately can actually make your
        code more stable. So how do you engineer this chaos in a helpful way,
        and how can you get buy-in from your manager and clients? Also, what's
        with the coffee today? Tune in to find out!
      </p>
    ),
    link: "https://ia902509.us.archive.org/7/items/BugHuntersCafeS2Ep6/BugHuntersCafeS2Ep6.mp3",
    info: "50min",
    note: `<div><p>Mikolaj Pawlikowski: </p><a href="https://mikolajpawlikowski.com" target="_blank">https://mikolajpawlikowski.com</a></div>
           <div><p>"Chaos Engineering" by Mikolaj Pawlikowski: </p><a href="https://www.manning.com/books/chaos-engineering" target="_blank">https://www.manning.com/books/chaos-engineering</a></div>
           <div><p>Music by: </p><a href="https://audionautix.com" target="_blank">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank">https://www.softerrific.com</a></div>
           <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com" target="_blank">https://mousepawmedia.com</a></div>
           <div><p>Sponsored by Manning Publications: </p><a href="https://manning.com" target="_blank">https://manning.com</a></div>`,
  },
  {
    id: 19,
    image: episode7,
    title: "Season 2, Episode 7: Andrea Goulet and Naomi Ceder",
    summary: (
      <p>
        A portal outage won't stop Andrea Goulet and Naomi Ceder from coming
        into the café for coffee and conversation. Listen in as they discuss
        empathy in open source software development.
      </p>
    ),
    link: "https://ia902502.us.archive.org/34/items/BugHuntersCafeS2Ep7/BugHuntersCafeS2Ep7.mp3",
    info: "63min",
    note: `<div><p>Empathy in Tech: </p><a href="https://empathyintech.com" target="_blank">https://empathyintech.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com" target="_blank">https://www.softerrific.com</a></div>
           <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com" target="_blank">https://mousepawmedia.com</a></div>
           <div><p>Sponsored by Manning Publications: </p><a href="https://manning.com" target="_blank">https://manning.com</a></div>`,
  },
  {
    id: 20,
    image: episode8,
    title: "Season 2, Episode 8: Tomasz Lelek",
    summary: (
      <p>
        Tomasz teaches Jason and Bojan how to navigate tradeoffs in code, while
        Annie struggles to manage the temporary unicorn horn she's been stuck
        with.
      </p>
    ),
    link: "https://ia601403.us.archive.org/30/items/BugHuntersCafeS2Ep8/BugHuntersCafeS2Ep8.mp3",
    info: "45min",
    note: `<div><p>Twitter: </p><a href="https://twitter.com/tomekl007">https://twitter.com/tomekl007</a></div>
           <div><p>"Software Mistakes and Tradeoffs" by Tomaz Lelek and Jon Skeet: </p><a href="https://www.manning.com/books/software-mistakes-and-tradeoffs">https://www.manning.com/books/software-mistakes-and-tradeoffs</a></div>
           <div><p>Music by: </p><a href="https://audionautix.com">https://audionautix.com</a></div>
           <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com">https://www.softerrific.com</a></div>
           <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com">https://mousepawmedia.com</a></div>
           <div><p>Sponsored by Manning Publications: </p><a href="https://manning.com">https://manning.com</a></div>`,
  },
  {
    id: 21,
    image: episode9,
    title: "Season 2, Episode 9: Reuven Lerner",
    summary: (
        <p>
            Jason and a particularly floral Bojan chat with Reuven Lerner about teaching Python,
            and the worst bugs they've seen (and caused). Meanwhile, Annie tries to undo a unicorn magic glitch
            of her own.
        </p>
    ),
    link: "https://ia601407.us.archive.org/9/items/BugHuntersCafeS2Ep9/BugHuntersCafeS2Ep9.mp3",
    info: "63min",
    note: `<div><p>Reuven Lerner's website: <a href="https://lerner.co.il/">https://lerner.co.il</a></p></div>
       <div><p>Twitter: </p><a href="https://twitter.com/reuvenmlerner">https://twitter.com/reuvenmlerner</a></div>
       <div><p>"Pandas Workout" by Reuven Lerner: </p><a href="https://www.manning.com/books/pandas-workout">https://www.manning.com/books/pandas-workout</a></div>
       <div><p>Music by: </p><a href="https://audionautix.com">https://audionautix.com</a></div>
       <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com">https://www.softerrific.com</a></div>
       <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com">https://mousepawmedia.com</a></div>
       <div><p>Sponsored by Manning Publications: </p><a href="https://manning.com">https://manning.com</a></div>`,
  },
    {
        id: 22,
        image: episode10,
        title: "Season 2, Episode 10: Itamar Turner-Trauring",
        summary: (
            <p>
                Logs, anyone? Bojan and Jason chat with Itamar Turner-Trauring, the creator of the eliot
                logging library in Python.
            </p>
        ),
        link: "https://ia601407.us.archive.org/2/items/BugHuntersCafeS2Ep10/BugHuntersCafeS2Ep10.mp3",
        info: "60min",
        note: `<div><p>Itamar Turner-Trauring's website: <a href="http://itamarst.org/">http://itamarst.org/</a></p></div>
       <div><p>Python Speed: <a href="https://pythonspeed.com/">https://pythonspeed.com/</a></p></div> 
       <div><p>Twitter: </p><a href="https://twitter.com/itamarst">https://twitter.com/itamarst</a></div>
       <div><p>Music by: </p><a href="https://audionautix.com">https://audionautix.com</a></div>
       <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com">https://www.softerrific.com</a></div>
       <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com">https://mousepawmedia.com</a></div>`,
    },
    {
        id: 23,
        image: episode11,
        title: "Season 2, Episode 11: Cecelia Martinez",
        summary: (
            <p>
                Time traveller Cecelia Martinez discusses frontend development and debugging with Bojan and Jason.
                Even so, the question remains: is that programming book from the future that Bojan bought from the
                rummage sale going to be useful in the end?
            </p>
        ),
        link: "https://ia804708.us.archive.org/20/items/BugHuntersCafeS2Ep11/BugHuntersCafeS2Ep11.mp3",
        info: "60min",
        note: `<div><p>Cecelia Martinez's website: <a href="https://www.ceceliacreates.com/">https://www.ceceliacreates.com/</a></p></div>
       <div><p>Twitter: <a href="https://twitter.com/ceceliacreates">https://twitter.com/ceceliacreates</a></p></div> 
       <div><p>Replay.io: </p><a href="https://www.replay.io/">https://www.replay.io/</a></div>
       <div><p>Music by: </p><a href="https://audionautix.com">https://audionautix.com</a></div>
       <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com">https://www.softerrific.com</a></div>
       <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com">https://mousepawmedia.com</a></div>`,
    },
    {
        id: 24,
        image: episode12,
        title: "Season 2, Episode 12: Alex Booker",
        summary: (
            <p>
                Jason broke reality...but on the upside, Alex Booker has spontaneously appeared. While he sticks
                around to discuss common mistakes junior developers make, Annie grapples with whether an
                eggplant macchiato was ever supposed to be a thing.
            </p>
        ),
        link: "https://ia601406.us.archive.org/35/items/BugHuntersCafeS2Ep12/BugHuntersCafeS2Ep12.mp3",
        info: "53min",
        note: `<div><p>The Scrimba Podcast: <a href="https://scrimba.com/podcast/">https://scrimba.com/podcast/</a></p></div>
       <div><p>Twitter: <a href="https://twitter.com/bookercodes">https://twitter.com/bookercodes</a></p></div> 
       <div><p>Music by: </p><a href="https://audionautix.com">https://audionautix.com</a></div>
       <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com">https://www.softerrific.com</a></div>
       <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com">https://mousepawmedia.com</a></div>`,
    },
    {
        id: 25,
        image: episode1,
        title: "Season 3, Episode 1: Edaqa Mortoray",
        summary: (
            <p>
                What makes a good puzzle? Software engineer and escape room enthusiast Edaqa Mortoray chats
                with Jason and Bojan about the joys and sorrows of independent projects, why debugging makes
                a terrible game, and how he came to be the top endorsed Monster Slayer on LinkedIn. Now if only
                someone can talk the unicorn out of forcing everyone to play supporting cast in his own personal
                sitcom.
            </p>
        ),
        link: "https://ia902608.us.archive.org/1/items/BugHuntersCafeS3Ep1/BugHuntersCafeS3Ep1.mp3",
        info: "59min",
        note: `<div><p>Edaqa's Website: <a href="https://edaqa.com/">https://edaqa.com/</a></p></div>
       <div><p>Edaqa's Room: <a href="https://edaqasroom.com/">https://edaqasroom.com/</a></p></div>
       <div><p>Music by: </p><a href="https://audionautix.com">https://audionautix.com</a></div>
       <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com">https://www.softerrific.com</a></div>
       <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com">https://mousepawmedia.com</a></div>`,
    },
    {
        id: 26,
        image: episode2,
        title: "Season 3, Episode 2: Pat Viafore and Jess Jang",
        summary: (
            <p>
                A quiet evening in the café provides the perfect opportunity for Jess to join in the conversation,
                this time with Pat Viafore, author of "Robust Python". Join the group as they discuss the challenges
                and intricacies of testing.
            </p>
        ),
        link: "https://ia601602.us.archive.org/13/items/BugHuntersCafeS3Ep2/BugHuntersCafeS3Ep2.mp3",
        info: "65min",
        note: `<div><p>Pat Viafore's website: <a href="https://patviafore.com/">https://patviafore.com/</a></p></div>
       <div><p>Jess Jang's website: <a href="https://jessicajang.github.io/">https://jessicajang.github.io/</a></p></div>
       <div><p>"Robust Python" by Pat Viafore: <a href="https://www.oreilly.com/library/view/robust-python/9781098100650/">https://www.oreilly.com/library/view/robust-python/9781098100650/</a></p></div>
       <div><p>"Dead Simple Python" by Jason C. McDonald: <a href="https://nostarch.com/dead-simple-python">https://nostarch.com/dead-simple-python</a></p></div>
       <div><p>Music by: </p><a href="https://audionautix.com">https://audionautix.com</a></div>
       <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com">https://www.softerrific.com</a></div>
       <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com">https://mousepawmedia.com</a></div>`,
    },
    {
        id: 27,
        image: episode3,
        title: "Season 3, Episode 3: Aliénor Latour, Donia Chaiehloudj, and Pascal Bertrand",
        summary: (
            <p>
                A crowd of blue gophers has descended on the café, perhaps on account of the visit from the co-authors
                of "Learn Go with Pocket-Sized Projects": Aliénor Latour, Donia Chaiehloudj, and Pascal Bertrand.
                Listen in as they explain what makes Go tick, and talk about some of the strange bugs they've
                encountered across their diverse experiences as software engineers.
            </p>
        ),
        link: "https://ia902609.us.archive.org/0/items/BugHuntersCafeS3Ep3/BugHuntersCafeS3Ep3.mp3",
        info: "59min",
        note: `<div><p>"Learn Go with Pocket-Sized Projects website: <a href="https://www.manning.com/books/learn-go-with-pocket-sized-projects">https://www.manning.com/books/learn-go-with-pocket-sized-projects</a></p></div>
       <div><p>Aliénor Latour's Medium: <a href="https://alienorlatour.medium.com/">https://alienorlatour.medium.com/</a></p></div>
       <div><p>Donia Chaiehloudj's website: <a href="https://www.doniacld.com/">https://www.doniacld.com/</a></p></div>
       <div><p>Music by: </p><a href="https://audionautix.com">https://audionautix.com</a></div>
       <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com">https://www.softerrific.com</a></div>
       <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com">https://mousepawmedia.com</a></div>`,
    },
    {
        id: 28,
        image: episode4,
        title: "Season 3, Episode 4: Jocelyne Morin-Nurse",
        summary: (
            <p>
                A rogue vine is taking over the café, but that's not enough to stop Jocelyne Morin-Nurse
                from enjoying her coffee. Listen as she discusses leadership with Bojan and Jason, including
                how to handle the transition from "team member" to "team lead".
            </p>
        ),
        link: "https://ia902603.us.archive.org/0/items/BugHuntersCafeS3Ep4/BugHuntersCafeS3Ep4.mp3",
        info: "63min",
        note: `<div><p>Loxentus by Jocelyne Morin-Nurse: <a href="https://www.loxentus.com/">https://www.loxentus.com/</a></p></div>
       <div><p>Music by: </p><a href="https://audionautix.com">https://audionautix.com</a></div>
       <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com">https://www.softerrific.com</a></div>
       <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com">https://mousepawmedia.com</a></div>`,
    },
    {
        id: 29,
        image: episode5,
        title: "Season 3, Episode 5: Sam Taggart",
        summary: (
            <p>
                It's an afternoon of new perspectives in the café, both from climbing the rock candy wall and
                chatting about LabVIEW. Climbing enthusiast and LabVIEW trainer Sam Taggart belays in to chat about
                both, and how code interacts with the messy real world.
            </p>
        ),
        link: "https://ia902705.us.archive.org/2/items/BugHuntersCafeS3Ep5/BugHuntersCafeS3Ep5.mp3",
        info: "66min",
        note: `<div><p>SAS Workshops: <a href="https://sasworkshops.com/">https://sasworkshops.com/</a></p></div>
       <div><p>The LabVIEW Experiment Podcast: <a href="https://www.thelabviewexperiment.com/">https://www.thelabviewexperiment.com/</a></p></div>
       <div><p>Music by: </p><a href="https://audionautix.com">https://audionautix.com</a></div>
       <div><p>Sponsored by Softerrific: </p><a href="https://www.softerrific.com">https://www.softerrific.com</a></div>
       <div><p>Sponsored by MousePaw Media: </p><a href="https://mousepawmedia.com">https://mousepawmedia.com</a></div>`,
    },
];

export default episodes;
