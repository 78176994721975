import React, { useEffect } from "react";
import arrow from "../images/icons/arrow.svg";
import { Link } from "react-router-dom";
import EpisodesSlider from "./EpisodesSlider";

const Menu = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  return (
    <section className="the-menu">
      <div className="title-wrapper">
        <h1>{props.title}</h1>
      </div>

      <EpisodesSlider />
      <div className="episodes-link">
        <a href="/episodes">see all episodes</a>
      </div>
    </section>
  );
};

export default Menu;
