import React from "react";
import AudioPlayer from "react-h5-audio-player";
import playIcon from "../images/icons/play.svg";
import arrow from "../images/icons/arrow.svg";
import slickarrow from "../images/icons/slickarrow.svg";
import { Link, useParams } from "react-router-dom";
import episodes from "./episodeList";
import { isMobileView } from "../utils";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const EpisodePlayer = () => {
  let { id } = useParams();
  const currentEpisode = episodes.find((item) => {
    return item.id == id;
  });
  const currentEpisodeIndex = episodes.findIndex((item) => {
    return Number(item.id == id);
  });
  const currentEpisodeArrayPosition = currentEpisodeIndex + 1;

  const hasNextSlide = () => {
    return episodes.length > currentEpisodeArrayPosition;
  };

  const hasPreviousSlide = () => {
    return currentEpisodeIndex != 0;
  };

  const nextEpisodeId = hasNextSlide()
    ? episodes[currentEpisodeIndex + 1].id
    : null;
  const prevEpisodeId = hasPreviousSlide()
    ? episodes[currentEpisodeIndex - 1].id
    : null;

  return (
    <div>
      <section className="player-section single-episode-player wrapper">
        <div className="player-slider">
          <div className="side-arrow prev">
            {prevEpisodeId && (
              <div>
                <Link to={`/episode/${prevEpisodeId}`}>
                  <img
                    src={slickarrow}
                    alt="Left arrow"
                    className="rotate-180 slickarrowcustom"
                  />
                </Link>
                <p>PREV</p>
              </div>
            )}
          </div>
          <div
            className="player-wrapper"
            style={{ backgroundImage: `url("${currentEpisode.image}")` }}
          >
            <h3>{currentEpisode && currentEpisode.title}</h3>
            <AudioPlayer
              customIcons={{
                play: <img src={playIcon} className={"player-play"} />,
              }}
              layout={isMobileView() ? "vertical" : "horizontal"}
              showJumpControls={false}
              customAdditionalControls={[]}
              src={currentEpisode && currentEpisode.link}
              autoPlay={false}
            />
          </div>
          <div className="side-arrow next">
            {nextEpisodeId && (
              <div>
                <Link to={`/episode/${nextEpisodeId}`}>
                  <img
                    src={slickarrow}
                    alt="Right arrow"
                    className="slickarrowcustom"
                  />
                </Link>
                <p>NEXT</p>
              </div>
            )}
          </div>
        </div>
        <div className="episode-sum">
          <h2>Episode summary</h2>
          <p>{currentEpisode && currentEpisode.summary}</p>
        </div>
        <div className="notes-section">
          <h3>Show Notes</h3>
          <div
            className="notes"
            dangerouslySetInnerHTML={{
              __html: currentEpisode && currentEpisode.note,
            }}
          />
        </div>
      </section>
    </div>
  );
};

export default EpisodePlayer;
