import React from "react";
import { socialArray } from "./Logos";

const SocialBar = (props) => {
  return (
    <div className="social-bar-section">
      <div className="listen-on">
        <div className="title-wrapper">
          <h1>{props.title}</h1>
        </div>
      </div>
      <div className="social-bar">
        {socialArray.map((network) => {
          return (
            <a key={network.id} href={network.link} target="_blank" rel="noreferrer">
              <img src={network.image} alt="social logo" />
            </a>
          );
        })}
      </div>
    </div>
  );
};
export default SocialBar;
