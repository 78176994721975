import React, { useEffect } from "react";
import episodes from "./episodeList";

const EpisodePage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);
  console.log(episodes);
  return (
    <section className="episodes">
      <div className="title-wrapper">
        <h1>{props.title}</h1>
      </div>
      <div className="episodes-list">
        {episodes.map((episode, i) => {
          return (
            <div>
              <a href={episode.link}>{episode.title}</a>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default EpisodePage;
