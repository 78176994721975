import React from "react";
import logo from "../images/logo-og.png";
import aboutUs from "../images/about-us.svg";
import { Link, withRouter, useLocation } from "react-router-dom";

const Header = (props) => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <header className="header">
      <div className="logo">
        <Link to={"/"} className="logo-link">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      {/* <div className="isMobileView">
        <Link to={"/"}>
          <img src={logo} alt="Logo" className="mobile-logo isMobileView" />
        </Link>
      </div> */}

      <a href="/about-us" className="about-link">
        <img src={aboutUs} alt="about" />
      </a>
    </header>
  );
};

export default withRouter(Header);
