import "react-h5-audio-player/lib/styles.css";
import "./scss/app.scss";
import Menu from "./components/TheMenu";
import AboutUs from "./components/AboutUs";
import Footer from "./components/Footer";
import SocialBar from "./components/SocialBar";
import React, { useState } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import EpisodePlayer from "./components/EpisodePlayer";
import EpisodeNote from "./components/EpisodeNote";
import EpisodePage from "./components/EpisodePage";
import episodes from "./components/episodeList";

function App() {
  const [title, setTitle] = useState("The Menu");
  const [number, setNumber] = useState("");

  //Set arrow to go back to the previous section not back to history
  // const [backPath,setBackPath] = useState('/');
  const changeTitle = (newTitle) => {
    setTitle(newTitle);
  };

  return (
    <Router>
      <div className="app">
        <Header title={title} number={number} />
        <main className="no-scroll">
          <Switch>
            <Route exact path="/">
              <Menu
                setTitle={changeTitle}
                setNumber={setNumber}
                episodes={episodes}
                title={"The Menu"}
              />
            </Route>
            <Route path="/about-us">
              <AboutUs
                setTitle={changeTitle}
                setNumber={setNumber}
                title={"About Us"}
              />
            </Route>
            <Route path="/episode/:id">
              <EpisodePlayer
                setTitle={changeTitle}
                setNumber={setNumber}
                episodes={episodes}
              />
            </Route>
            <Route path="/episode-note/:id">
              <EpisodeNote
                setTitle={changeTitle}
                setNumber={setNumber}
                episodes={episodes}
              />
            </Route>
            <Route path="/listen">
              <SocialBar
                setTitle={changeTitle}
                setNumber={setNumber}
                title={"Listen On"}
              />
            </Route>
            <Route path="/episodes">
              <EpisodePage
                setTitle={changeTitle}
                setNumber={setNumber}
                title={"Episodes"}
              />
            </Route>
          </Switch>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
