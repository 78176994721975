import React from 'react';
import {useParams} from "react-router-dom";
import episodes from "./episodeList";
const EpisodeNote = (props) => {
    let { id } = useParams();

    props.setTitle("Episode");
    props.setNumber(id);

    const arrayId = Math.max(0,id-1);
    let episode = episodes[arrayId];
    if(!episode){
        episode = episodes[0];
    }

    return (
        <section className="about-us">
            <div className="about-us-content" dangerouslySetInnerHTML={{__html:episode.note}}>
                {/*<p>*/}
                {/*    {episode.note}*/}
                {/*</p>*/}
            </div>
        </section>
    )
}

export default EpisodeNote;